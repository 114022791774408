import React      from "react"
import Layout     from "../components/layout"
import Para       from "../components/paragraph"

const TXT1 =<p>
						Ab Oldenburg benutzen Sie die <b>A29 Oldenburg-Wilhelmshaven</b>, bis zur Ausfahrt <b>Nr.6 Sande Mariensiel</b>.
						Rechts auf die <b>(K99)</b>  bis zum Kreisverkehr. Die dritte Abfahrt, Richtung <b>Wilhelmshaven - Sande (L815)</b>
						bis zur Abfahrt <b>Mariensiel</b>.
						Bis Mariensiel auf der <b>(K312)</b>.
						Links durch das alte Sieltor bis zum Ende der Straße. Rechts in die <b>Wilhelmshavener Str.</b>
						Nach ca.100m ist das Ziel, links in dem <b>Sielweg Nr.5</b>.
						Navi Adresse: <b>26452 Sande  Wilhelmshavener Str.21</b>, dann links In den Sielweg.
						<br /><br />
						Oder ganz genau: <b>N53.51080°  E8.05069°</b><br /></p>

const Location = () => (
	<Layout head_gif = "anfahrt_n_bhb.png">
	  <Para txt="Anfahrt" my_img="karte.jpg" my_txt={TXT1}/>
	</Layout>
);
export default Location;
